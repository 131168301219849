import React, { useState } from "react";
import { useEffectAsync } from "../../../../common/hooks/use-effect-async";
import { CircularProgress, MenuItem, Select } from "@mui/material";
import { t } from "i18next";
import { PalletShippingInformationSelectItem } from "./pallet-shipping-information-select-item";
import { usePalletShippingContext } from "./pallet-shipping-context";

export enum ShippingInformationType {
    PreviousPalletShipping = "previousPalletShipping",
    PickupSlot = "pickupSlots",
}

interface IPalletShippingInformationSelect {
    shippingInformationType: ShippingInformationType;
    loadData: () => Promise<PalletShippingInformationSelectItem[]>;
    onSelect: (selectedItem: PalletShippingInformationSelectItem) => void;
}

export const PalletShippingInformationSelect = (props: IPalletShippingInformationSelect) => {
    const [shippingInformationList, setShippingInformationList] = useState<PalletShippingInformationSelectItem[]>([]);
    const [shippingId, setShippingId] = useState("placeholder");
    const [loadingData, setLoadingData] = useState(true);

    const {
        setIsFormValid,
    } = usePalletShippingContext();

    useEffectAsync(async () => {
        setShippingInformationList(await props.loadData());
        setLoadingData(false);
    }, []);

    const handleOnSelect = (id: string) => {
        setShippingId(id);
        const shippingInformation = shippingInformationList?.find(si => si.id === id);

        if (shippingInformation) {
            props.onSelect(shippingInformation);
        }

        setIsFormValid(shippingInformation !== undefined);
    };

    return <div className={"pallet-shipping-form__select"}>
        <Select id="shipping-information-select"
                data-testid={"ship-pallet-shipping-information-select"}
                value={shippingId}
                disabled={shippingInformationList.length === 0}>
            {loadingData &&
                <MenuItem value={"placeholder"} key={"shipping-information-loading"}>
                    <CircularProgress size={20}/>
                    {t(`components.pallet.shipping.${props.shippingInformationType}.informationLoading`)}
                </MenuItem>}
            {!loadingData && shippingInformationList.length === 0 &&
                <MenuItem value={"placeholder"} key={"shipping-information-loading"}>
                    {t(`components.pallet.shipping.${props.shippingInformationType}.informationNotFound`)}
                </MenuItem>}
            {shippingInformationList.map(si =>
                <MenuItem data-testid={`shipping-information-select-item-${si.id}`}
                          onClick={() => handleOnSelect(si.id)}
                          key={si.id}
                          value={si.id}>
                    {si.formatted}
                </MenuItem>)
            }
        </Select>
    </div>;
};
