import { Button, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import React from "react";
import { sumBy } from "lodash";
import { SquaredChip } from "../../../../common/component/squared-chip/squared-chip";
import { blue } from "@mui/material/colors";
import { t } from "i18next";
import moment from "moment";
import { PalletsListItem } from "../pallets-list/pallets-list-item";
import { PalletShippingInformationSelect, ShippingInformationType } from "./pallet-shipping-information-select";
import { palletService } from "../../../services/pallet";
import { context } from "../../../../context/context";
import { PalletShippingInformationSelectItem } from "./pallet-shipping-information-select-item";
import { PalletShippingInformationInput } from "./pallet-shipping-information-input";
import { usePalletShippingContext } from "./pallet-shipping-context";

import "./style.scss";

interface IPalletShippingDetailsProps {
    shippingPalletsList: PalletsListItem[];
    isTruckIdentifierRequired: boolean;
    useExistingShippingInformation: boolean;

    onCancel: () => void;
    onSubmit: (palletList: PalletsListItem[], truckId: string, pickingDate: moment.Moment, sealNumber: string, pickupSlotId: string) => void;
}

interface IPalletShippingDetailsTitleProps {
    shippingPalletsList: PalletsListItem[];
}

export const PalletShippingDetails = (props: IPalletShippingDetailsProps) => {
    const {
        isFormValid,
        truckId, setTruckId,
        pickingDate, setPickingDate,
        sealNumber, setSealNumber,
        pickupSlotId,
    } = usePalletShippingContext();

    const handleFormSubmission = () => {
        props.onSubmit(props.shippingPalletsList, truckId, pickingDate, sealNumber, pickupSlotId);
    };

    const handleOnSelect = (selectedItem: PalletShippingInformationSelectItem) => {
        setTruckId(selectedItem.truckIdentifier);
        setPickingDate(moment(selectedItem.pickingDate));
        setSealNumber(selectedItem.containerSealNumber);
    };

    const loadPreviousShippingInformation = async () => {
        const response = await palletService.loadShippingInformation(context.warehouseCode);

        return response.map(si => new PalletShippingInformationSelectItem(
            si.id,
            si.pickingDate,
            si.truckIdentifier,
            si.containerSealNumber,
            si.format(),
        ));
    };

    return <>
        <PalletShippingDetailsTitle shippingPalletsList={props.shippingPalletsList} />
        <DialogContent>
            <div className={"pallet-shipping-summary"}>
                <Typography>
                    {t("components.pallet.shipping.description", {
                        count: props.shippingPalletsList.length,
                    })}
                </Typography>
                <div className={"pallet-shipping-summary__list"}>
                    {props.shippingPalletsList.map(c => <SquaredChip propertyKey={`item-${c.code}`} color={blue} label={c.code} />)}
                </div>
                <Typography className={"pallet-shipping-details__summary"}>
                    {t("components.pallet.shipping.descriptionParcelCount", {
                        count: sumBy(props.shippingPalletsList, "parcelCount"),
                    })}
                </Typography>
            </div>
        </DialogContent>
        <DialogContent>
            <div className={"pallet-shipping-form"}>
                <Typography className={"pallet-shipping-form__headline"}>
                    {t("components.pallet.shipping.informationTitle")}
                </Typography>
                <Typography>
                    {t("components.pallet.shipping.informationDetails")}
                </Typography>
                {!props.useExistingShippingInformation &&
                    <PalletShippingInformationInput isTruckIdentifierRequired={props.isTruckIdentifierRequired} /> }
                {props.useExistingShippingInformation &&
                    <PalletShippingInformationSelect shippingInformationType={ShippingInformationType.PreviousPalletShipping}
                                                     loadData={loadPreviousShippingInformation} onSelect={handleOnSelect} />}
            </div>
        </DialogContent>
        <DialogActions>
            <Button color={"inherit"}
                    variant="contained"
                    type="submit"
                    data-testid={"pallet-shipping-cancel"}
                    onClick={async () => props.onCancel()}>
                {t("components.popin.cancel")}
            </Button>
            <Button color="secondary"
                    variant="contained"
                    type="submit"
                    data-testid={"pallet-shipping-submit"}
                    key="submit-btn"
                    disabled={!isFormValid}
                    onClick={() => handleFormSubmission()}>
                {t("components.pallet.shipping.ship")}
            </Button>
        </DialogActions>
    </>;
};

const PalletShippingDetailsTitle = (props: IPalletShippingDetailsTitleProps) => {
    return <DialogTitle>
        {props.shippingPalletsList.length > 1 ?
            t("components.pallet.shipping.multiplePalletsTitle", {
                count: props.shippingPalletsList.length,
            }) :
            t("components.pallet.shipping.singlePalletTitle", {
                palletCode: props.shippingPalletsList[0].code,
            })}
    </DialogTitle>;
};
