import React, { SetStateAction, useContext } from "react";
import { createContext, ReactNode, useState } from "react";
import moment from "moment/moment";

interface IPalletShippingContext {
    isFormValid: boolean;
    truckId: string;
    pickingDate: moment.Moment;
    sealNumber: string;
    pickupSlotId: string;
    setIsFormValid: React.Dispatch<SetStateAction<boolean>>;
    setTruckId: React.Dispatch<SetStateAction<string>>;
    setPickingDate: React.Dispatch<SetStateAction<moment.Moment>>;
    setSealNumber: React.Dispatch<SetStateAction<string>>;
    setPickupSlotId: React.Dispatch<SetStateAction<string>>;
}

const defaultContext: IPalletShippingContext = {
    isFormValid: false,
    truckId: "",
    pickingDate: moment(),
    sealNumber: "",
    pickupSlotId: "",
    // tslint:disable-next-line:no-empty
    setIsFormValid: () => {},
    // tslint:disable-next-line:no-empty
    setTruckId: () => {},
    // tslint:disable-next-line:no-empty
    setPickingDate: () => {},
    // tslint:disable-next-line:no-empty
    setSealNumber: () => {},
    // tslint:disable-next-line:no-empty
    setPickupSlotId: () => {},
};

const PalletShippingContext = createContext<IPalletShippingContext>(defaultContext);

interface IPalletShippingContextProviderProps {
    children: ReactNode;
}

export const PalletShippingContextProvider = (props: IPalletShippingContextProviderProps) => {
    const [isFormValid, setIsFormValid] = useState(false);
    const [truckId, setTruckId] = useState("");
    const [pickingDate, setPickingDate] = useState(moment());
    const [sealNumber, setSealNumber] = useState("");
    const [pickupSlotId, setPickupSlotId] = useState("");

    return (<PalletShippingContext.Provider value={
        {
            isFormValid, setIsFormValid,
            truckId, setTruckId,
            pickingDate, setPickingDate,
            sealNumber, setSealNumber,
            pickupSlotId, setPickupSlotId,
        }}>
        {props.children}
    </PalletShippingContext.Provider>);
};

export const usePalletShippingContext = () => {
  return useContext(PalletShippingContext);
};
