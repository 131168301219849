import React, { useState } from "react";
import { pickupSlotsService } from "../../../services/pickup-slots-service";
import { PalletShippingInformationSelectItem } from "./pallet-shipping-information-select-item";
import { Checkbox, FormControl, FormControlLabel, FormLabel, TextField } from "@mui/material";
import { t } from "i18next";
import { PalletShippingInformationSelect, ShippingInformationType } from "./pallet-shipping-information-select";
import { usePalletShippingContext } from "./pallet-shipping-context";
import { useEffectAsync } from "../../../../common/hooks/use-effect-async";
import moment from "moment";
import { useWarehouseContext } from "../warehouse-context/warehouse-context-provider";

interface IPalletShippingInformationInputProps {
    isTruckIdentifierRequired: boolean;
}

export const PalletShippingInformationInput = (props: IPalletShippingInformationInputProps) => {
    const [useManualInput, setUseManualInput] = useState(false);

    const {
        isFormValid, setIsFormValid,
        truckId, setTruckId,
        pickingDate, setPickingDate,
        sealNumber, setSealNumber,
        setPickupSlotId,
    } = usePalletShippingContext();

    const warehouseContext = useWarehouseContext();

    useEffectAsync(async () => {
        if (useManualInput) {
            setIsFormValid(!props.isTruckIdentifierRequired || truckId !== "");
        } else {
            setIsFormValid(false || isFormValid);
        }
    }, [useManualInput, truckId]);

    const handleOnInput = (value: string, setValue: React.Dispatch<React.SetStateAction<string>>) => {
        setValue(value);

        setIsFormValid(!props.isTruckIdentifierRequired || truckId !== "");
    };

    const handleOnSelect = (selectedItem: PalletShippingInformationSelectItem) => {
        setTruckId(selectedItem.truckIdentifier);
        setPickingDate(moment(selectedItem.pickingDate));
        setSealNumber(sealNumber);
        setPickupSlotId(selectedItem.id);
    };

    const loadPickupSlots = async () => {
        const response = await pickupSlotsService.getPickupSlots(warehouseContext.code as string);

        return response.map(ps => new PalletShippingInformationSelectItem(
            ps.id,
            ps.estimatedArrivalDate,
            ps.truckLicensePlate,
            "",
            ps.format(),
        ));
    };

    const displayManualInput = () => {
        return <div className={"pallet-shipping-form__container"}>
            <FormControl variant={"outlined"}
                         fullWidth>
                <FormLabel htmlFor={"ship-picking-date"}>
                    {t("components.pallet.shipping.pickingDate")}
                </FormLabel>
                <TextField id="ship-picking-date"
                           variant={"outlined"}
                           data-testid={"ship-picking-date"}
                           value={pickingDate.format("Y-MM-DD")}
                           disabled={true}/>
            </FormControl>
            <FormControl variant={"outlined"}
                         fullWidth>
                <FormLabel htmlFor={"ship-truck-id"}>
                    {t("components.pallet.shipping.truckId")}
                </FormLabel>
                <TextField id={"ship-truck-id"}
                           variant={"outlined"}
                           data-testid={"ship-truck-id"}
                           onChange={event => handleOnInput(event.target.value, setTruckId)}
                           value={truckId}/>
            </FormControl>
            <FormControl variant={"outlined"}
                         fullWidth>
                <FormLabel htmlFor={"ship-truck-id"}>
                    {t("components.pallet.shipping.containerSealNumber")}
                </FormLabel>
                <TextField id={"ship-truck-id"}
                           variant={"outlined"}
                           data-testid={"ship-truck-id"}
                           onChange={event => handleOnInput(event.target.value, setSealNumber)}
                           value={sealNumber}/>
            </FormControl>
        </div>;
    };

    const displayPickupSlots = () => {
        return <>
            <FormLabel>
                {t("components.pallet.shipping.pickupSlot")}
            </FormLabel>
            <PalletShippingInformationSelect shippingInformationType={ShippingInformationType.PickupSlot}
                                             loadData={loadPickupSlots} onSelect={handleOnSelect} />
        </>;
    };

    return <>
        <div className={"pallet-shipping-form__container"}>
            {useManualInput ? undefined
                : <FormControl variant={"outlined"}
                         fullWidth>
                <FormLabel htmlFor={"ship-truck-id"}>
                    {t("components.pallet.shipping.containerSealNumber")}
                </FormLabel>
                <TextField id={"ship-truck-id"}
                           variant={"outlined"}
                           data-testid={"ship-truck-id"}
                           onChange={event => handleOnInput(event.target.value, setSealNumber)}
                           value={sealNumber}/>
            </FormControl>}
        </div>
        {useManualInput ? displayManualInput() : displayPickupSlots()}
        <FormControlLabel control={<Checkbox onChange={() => setUseManualInput(!useManualInput)} checked={useManualInput} />}
                          label={t("components.pallet.shipping.useManualInput")}/>
    </>;
};
